import { useEffect, useMemo, useState } from 'react';

import { HoverText } from '@/components/HoverText';
import LoadingSpinner from '@/components/LoadingSpinner';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { useOverview } from '@/pages/overview/common/utils';
import { dataRoomProcessingType } from '@/pages/overview/dataroom/content/common/types';

interface DataRoomToolButtonProps {
  name: string;
  icon: string;
  toolIdentifier: string;
  isDisabled?: boolean;
  onClick: () => void;
}

export const DataRoomToolButton = ({
  name,
  icon,
  toolIdentifier,
  isDisabled,
  onClick,
}: DataRoomToolButtonProps) => {
  const { matter } = useOverview();
  const { toolProgress } = useDataRoom();
  const [isButtonHovered, setIsButttonHovered] = useState(false);

  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const filesAreProcessing = useMemo(() => {
    return !matter?.number || matter?.fileUploadPercentComplete != 100;
  }, [matter?.fileUploadPercentComplete, matter?.number]);

  const toolProcessing: dataRoomProcessingType = {
    startTime: new Date(matter?.createdAt),
    finishTime: new Date(
      new Date(matter?.createdAt).getTime() +
        1000 * 60 * 3 * Math.ceil(matter?.dataRoomFiles.length / 10.0),
    ),
    complete: toolIdentifier === 'REDLINE' || !filesAreProcessing,
  };

  const generalToolProgress = toolProgress(
    toolProcessing.startTime,
    toolProcessing.finishTime,
    date,
  );

  const handleClick = () => {
    if (!isDisabled) {
      onClick();
      setIsButttonHovered(false);
    }
  };

  return (
    <div
      className={`${
        isDisabled ? 'cursor-default bg-[rgba(32,32,32,0.5)]' : 'hover:bg-[#303030]'
      } ${toolProcessing.complete && !isDisabled ? 'cursor-pointer hover:bg-[#303030]' : 'cursor-default'} relative mr-[4px] flex h-[60px] w-[200px] rounded-[8px] bg-[#1F1F1F] py-[20px] xlarge:w-[124px]`}
      onClick={handleClick}
      onMouseEnter={() => setIsButttonHovered(true)}
      onMouseLeave={() => setIsButttonHovered(false)}
    >
      <div
        className={`${!toolProcessing.complete ? 'absolute left-0 top-0 z-0 h-full rounded-l-[8px]' : 'hidden'}`}
        style={{
          width: `${generalToolProgress}%`,
          backgroundColor: '#303030',
        }}
      ></div>
      <div
        className={`${!toolProcessing.complete && 'w-full opacity-50'} relative z-10 flex gap-[13px] pl-[13px] pr-[8px]`}
      >
        <img src={icon} className="size-[20px]" />
        <span className="text-[14px]">{name}</span>
        {!toolProcessing.complete && (
          <div className="absolute right-2">
            <LoadingSpinner />
          </div>
        )}
      </div>
      {isButtonHovered ? (
        <HoverText
          isButtonHovered={isButtonHovered}
          title={name}
          isDisabled={isDisabled}
          isLoading={filesAreProcessing}
          hoverStyle="absolute left-1/2 transform -translate-x-1/2 translate-y-[42px]"
        />
      ) : (
        ''
      )}
    </div>
  );
};
