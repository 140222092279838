import * as d3 from 'd3';
import d3Menu from 'd3-context-menu';

import {
  CLUSTER_CANVAS_HEIGHT,
  CLUSTER_CANVAS_WIDTH,
  CLUSTER_CLEARANCE,
  CLUSTER_EXPAND_RATIO,
  CLUSTER_OFFSET,
  CLUSTER_RADIUS,
  CLUSTER_SHRINK_RATIO,
  SIMULATION_ALPHA_DECAY,
  SIMULATION_VELOCITY_DECAY,
} from '@/pages/overview/redline/clustering/d3/constants';

export interface Diff extends d3.SimulationNodeDatum {
  distance: number;
  id: string;
  neighbor: {
    id: string;
    file: {
      name: string;
      url: string;
    } | null;
  };
}
export interface Cluster extends d3.SimulationNodeDatum {
  id: string;
  name: string;
  templateFile: {
    id: string;
    file: {
      url: string;
      name: string;
    } | null;
  };
  diffs: Diff[];
}

/**
 * The function drawCLuster uses d3 and cluster information to draw Clusters
 * using d3 simultations
 * @param clusters is a list of all the clusters to draw
 * @param onEnd This function will be called when the cluster svg has
 * been drawn
 * @param onClick This function will be called when user clicks on cluster
 * @returns Returns a d3 simulation object
 */
export const drawCluster = (
  clusters: Cluster[],
  focusedCluster: string,
  {
    onFocus,
    onEnd,
    onRedline,
    onAddFile,
  }: {
    onFocus: (id: string) => void;
    onEnd: () => void;
    onRedline: (id: string) => void;
    onAddFile: (clusterName: string) => void;
  },
) => {
  const width = CLUSTER_CANVAS_WIDTH,
    height = CLUSTER_CANVAS_HEIGHT;
  const simulation = d3
    .forceSimulation(clusters)
    .velocityDecay(SIMULATION_VELOCITY_DECAY)
    .alphaDecay(SIMULATION_ALPHA_DECAY)
    .force('charge', d3.forceManyBody().strength(-CLUSTER_RADIUS).distanceMax(100))
    .force('center', d3.forceCenter(width / 3, height))
    .force('collide', d3.forceCollide(CLUSTER_CLEARANCE))
    .on('tick', updateClusters)
    .on('end', endName);

  /**
   *  updateClusters updates the position of cluster on each tick of
  d3.simulation, we have also added a loop that programmatically call ticks so
  the simultation ends quickly, otherwise simulation will take more time
  */
  function updateClusters() {
    for (let i = 0; i < 200; i++) {
      simulation.tick();
    }
    const menu = (d: Cluster) => [
      {
        title: `Cluster ${d.name}`,
      },
      {
        divider: true,
      },
      {
        title: 'Go to Redline',
        action: (d: Cluster) => onRedline(d.id),
      },
      {
        title: 'Add File',
        action: (d: Cluster) => onAddFile(d.name),
      },
    ];

    const cluster = d3
      .select('.cluster')
      .selectAll('svg')
      .data(clusters)
      .join('svg')
      .attr('class', 'group')
      .attr('x', (d) => d.x || 0)
      .attr('y', (d) => d.y || 0);

    cluster
      .selectAll('circle')
      .data((d) => [d])
      .join('circle')
      .attr('cx', CLUSTER_RADIUS + CLUSTER_OFFSET)
      .attr('cy', CLUSTER_RADIUS + CLUSTER_OFFSET)
      .attr('r', (d) =>
        focusedCluster === ''
          ? CLUSTER_RADIUS
          : focusedCluster === d.id
            ? CLUSTER_RADIUS * CLUSTER_EXPAND_RATIO
            : CLUSTER_RADIUS / CLUSTER_SHRINK_RATIO,
      )
      .style('stroke', '#989798')
      .style('stroke-width', '2px')
      .style('stroke-dasharray', '4,3')
      .style('pointer-events', 'hand')
      .style('align', 'center')
      .style('transition', 'all 1s')
      .on('click', (e, d) => onFocus(d.id))
      .filter((d) => d.id === focusedCluster)
      .on('click', d3Menu(menu));

    cluster
      .selectAll('.cluster-title')
      .data((d) => [d])
      .join('text')
      .attr('class', 'cluster-title')
      .text((d) => `Cluster ${d.name}`)
      .attr('x', CLUSTER_RADIUS * 1.3)
      .attr('y', (d) => (focusedCluster === '' ? 80 : focusedCluster === d.id ? 100 : 200))
      .style('font-size', '20px')
      .style('font-weight', '700')
      .attr('fill', '#FFFFFF');

    cluster
      .selectAll('foreignObject')
      .data((d) => [d])
      .join('foreignObject')
      .attr('class', (d) =>
        focusedCluster === d.id
          ? 'h-[32px] w-[95px] cursor-pointer rounded border-[1px] pt-[3px] text-center align-middle text-[#868686] hover:bg-[#2E2E2E] hover:text-[white]'
          : 'hidden',
      )
      .text('Redline')
      .attr('x', CLUSTER_RADIUS * 1.3)

      .attr('y', CLUSTER_RADIUS * 2.5)
      .style('border', '1px solid')
      .attr('fill', '#868686')
      .on('click', function (e, d) {
        onRedline(d.id);
      });
  }
  function endName() {
    onEnd();
  }

  return simulation;
};
