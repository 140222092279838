import { useCallback, useState } from 'react';

import CheckMark from '@/assets/icons/checkmark-icon.svg';
import InfoIcon from '@/assets/icons/information-icon.svg';
import { useNewDiligenceWizard } from '@/contexts/create-new-diligence-wizard/utils';

interface ToolCardProps {
  toolName: string;
  toolIcon: string;
  toolIdentifier: string;
  toolTip: string;
  isToolAdded: boolean;
}

export const ToolCard = ({
  toolName,
  toolIcon,
  toolIdentifier,
  toolTip,
  isToolAdded,
}: ToolCardProps) => {
  const { updateNewDiligenceData, newDiligenceData } = useNewDiligenceWizard();
  const [isInfoIconHovered, setIsInfoIconHovered] = useState(false);

  const handleAddOrRemoveTool = useCallback(() => {
    let userSelectedTools = [...(newDiligenceData.selectedTools || [])];
    if (!isToolAdded) {
      if (!userSelectedTools.includes(toolIdentifier)) {
        userSelectedTools.push(toolIdentifier);
      }
    } else {
      userSelectedTools = userSelectedTools.filter((tool) => tool !== toolIdentifier);
    }

    updateNewDiligenceData({ selectedTools: userSelectedTools });
  }, [newDiligenceData.selectedTools, toolIdentifier, isToolAdded, updateNewDiligenceData]);

  return (
    <div
      className={`${isToolAdded ? 'bg-[#eaeaea]' : 'bg-[#1F1F1F]'} flex h-[159px] w-[274px] min-w-[220px] max-w-[320px] cursor-default flex-col justify-between rounded-[8px] bg-[#1F1F1F] p-[24px]`}
      data-testid="tool-card"
    >
      <div className="relative flex gap-[8px]" data-testid="tool-card-name">
        {!isInfoIconHovered && <img src={toolIcon} />}
        <span
          className={`${isToolAdded ? 'text-[#121212]' : 'text-marveri-white'} ${isInfoIconHovered ? 'text-[13px]' : 'text-[16px]'} w-[90%] text-left font-semibold`}
        >
          {isInfoIconHovered ? toolTip : toolName}
        </span>
        {isToolAdded && <img src={CheckMark} className="absolute right-0 top-0" />}
      </div>
      <div className="flex translate-y-2 items-center justify-between">
        <img
          src={InfoIcon}
          className={`${isToolAdded ? 'filter-dark' : 'opacity-50 hover:opacity-100'}`}
          onMouseEnter={() => setIsInfoIconHovered(true)}
          onMouseOut={() => setIsInfoIconHovered(false)}
        />
        <div
          className={`${isToolAdded ? 'bg-[#1F1F1F]' : 'bg-[#303030] hover:bg-[#424242]'} flex h-[30px] cursor-pointer items-center justify-center rounded-[34px] bg-[#303030] px-[12px] py-[10px] text-[14px] font-semibold`}
          onClick={handleAddOrRemoveTool}
          data-testid="tool-card-add-or-remove-tool"
        >
          {isToolAdded ? 'Remove' : 'Add'}
        </div>
      </div>
    </div>
  );
};
