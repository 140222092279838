import LogRocket from 'logrocket';
import { useCallback, useEffect, useState } from 'react';

import { ENVIRONMENT } from '@/constants';
import { useNewDiligenceWizard } from '@/contexts/create-new-diligence-wizard/utils';
import { HomeSidebar } from '@/pages/home/common/HomeSidebar';
import { ToolCard } from '@/pages/home/common/ToolCard';
import { toolList } from '@/pages/home/common/toolList';
import { DocumentSetUploader } from '@/pages/home/content/DocumentSetUploader';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';
import { trpcReact } from '@/utils/trpc';

export const HomeContent = () => {
  const { updateNewDiligenceData, newDiligenceData } = useNewDiligenceWizard();
  useEffect(() => {
    localStorage.setItem('folderOpenMap', '');
  }, []);

  const [files, setFiles] = useState<File[] | undefined>(newDiligenceData.dataRoomFiles);

  const user = trpcReact.user.getCurrentUser.useQuery().data;

  useEffect(() => {
    if (user && user.email && ENVIRONMENT === 'production') {
      LogRocket.identify('user', {
        name: user?.firstName + ' ' + user?.lastName,
        email: user?.email || 'Unknown',
      });
    }
  }, [user]);

  const addFiles = useCallback((newFiles: File[]) => {
    setFiles((files) => {
      if (files) {
        return [...files, ...newFiles];
      } else {
        return newFiles;
      }
    });
  }, []);

  const deleteFiles = useCallback(
    (fileToDelete: File) => {
      const filteredFileList = files?.filter(
        (currentFile) => currentFile.name !== fileToDelete.name,
      );
      setFiles(filteredFileList);
      updateNewDiligenceData({ dataRoomFiles: filteredFileList });
    },
    [files, updateNewDiligenceData],
  );

  const checkIfToolSelected = useCallback(
    (toolIdentifier: string) => {
      const userSelectedTools = newDiligenceData.selectedTools;
      if (userSelectedTools) {
        return userSelectedTools.includes(toolIdentifier);
      } else {
        return false;
      }
    },
    [newDiligenceData.selectedTools],
  );

  useEffect(() => {
    updateNewDiligenceData({ selectedTools: ['REDLINE'] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkForToolSubtitle = () => {
    if (files && newDiligenceData.selectedTools) {
      return files.length > 0 && newDiligenceData.selectedTools.length === 0;
    }
  };

  return (
    <div
      className={`relative z-[1] flex size-full bg-container-dark py-[12px] pr-[12px] text-marveri-white`}
      data-testid="home-component"
    >
      <HomeSidebar />
      <div className="size-full cursor-default rounded-[12px] bg-marveri-background px-[16px] pb-[16px] pt-[24px] text-center">
        <h1 className="pb-[32px] text-[32px] font-semibold text-marveri-white">
          Welcome to Marveri, {user?.firstName}
        </h1>
        <DocumentSetUploader
          onUploadDone={addFiles}
          onFileDelete={deleteFiles}
          totalCount={files?.length || 0}
        />
        <div>
          <h1 className="mb-[16px] text-left text-[20px]">Tools</h1>
          {checkForToolSubtitle() && <span>Or create project now and add tools later</span>}
        </div>
        <ScrollableDiv containerStyle="h-[calc(100vh-550px)]">
          <div className="flex flex-wrap gap-[12px] ">
            {toolList.map((tool, index) => {
              return (
                <ToolCard
                  key={index}
                  toolName={tool.name}
                  toolIcon={tool.icon}
                  toolIdentifier={tool.toolIdentifier}
                  toolTip={tool.toolTip}
                  isToolAdded={checkIfToolSelected(tool.toolIdentifier)}
                />
              );
            })}
          </div>
        </ScrollableDiv>
      </div>
    </div>
  );
};
