import ContractClauseIcon from '@/assets/icons/contract-clause-icon.svg';
import FinancingRightsIcon from '@/assets/icons/finance-rights-icon.svg';
import MissingDocIcon from '@/assets/icons/missing-doc-icon.svg';
import MissingSignatureIcon from '@/assets/icons/missing-signature-icon.svg';
import OrganizeIcon from '@/assets/icons/organize-icon.svg';
import RedlineIcon from '@/assets/icons/redline-icon.svg';
import RenameIcon from '@/assets/icons/rename-icon.svg';

export const toolList = [
  {
    name: 'Redline',
    icon: RedlineIcon,
    path: 'redline',
    toolIdentifier: 'REDLINE',
    toolTip:
      'Redlines selected groups of documents at once to confirm form similarites and identify any discrepancies between them',
  },
  {
    name: 'Rename',
    icon: RenameIcon,
    toolIdentifier: 'RENAME',
    path: '',
    toolTip: 'Renames selected documents with consistent legal conventions',
  },
  {
    name: 'Organize',
    icon: OrganizeIcon,
    path: '',
    toolIdentifier: 'ORGANIZE',
    toolTip: 'Organizes selected documents into folders',
  },
  {
    name: 'Missing Documents',
    icon: MissingDocIcon,
    path: 'missing-documents-identify',
    toolIdentifier: 'MISSING_DOCUMENT',
    toolTip:
      'Identifies documents that may be missing in your upload and links related documents to each another',
  },
  {
    name: 'Missing Signature',
    icon: MissingSignatureIcon,
    path: 'missing-signatures-debug',
    toolIdentifier: 'MISSING_SIGNATURE',
    toolTip: 'Identifies documents that may not be properly executed',
  },
  {
    name: 'Finance Review',
    icon: FinancingRightsIcon,
    path: 'financing-rights',
    toolIdentifier: 'FINANCE_REVIEW',
    toolTip:
      'Reviews every document for rights or obligations that may be triggered in a preferred stock financing.',
  },
  {
    name: 'Contract Clauses',
    icon: ContractClauseIcon,
    path: 'contract-clauses',
    toolIdentifier: 'CONTRACT_CLAUSES',
    toolTip:
      'Reviews all commercial agreements for terms that could be implicated in an M&A or other corporate transaction',
  },
];
