import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import RedlineIcon from '@/assets/icons/redline-icon.svg';
import ClusterIcon from '@/assets/images/clusters.svg';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { useOverview } from '@/pages/overview/common/utils';
import { DataRoomToolDropdown } from '@/pages/overview/dataroom/content/common/DataRoomToolDropdown';
import { createAndSelectRedlineFiles } from '@/pages/overview/redline/utils';

export const RedlineButton = () => {
  const { matter } = useOverview();
  const [processing, setProcessing] = useState(false);
  const [isRedlineButtonDisabled, setIsRedlineButtonDisabled] = useState(true);

  const navigate = useNavigate();
  const { checkedFiles } = useDataRoom();
  const onCluster = useCallback(async () => {
    if (checkedFiles.length === 0) return;
    setProcessing(true);
    try {
      const redlineName = `Redline ${matter.redlines.length + 1}`;
      await createAndSelectRedlineFiles(
        checkedFiles,
        matter.number,
        matter.client.number,
        redlineName,
      );
      navigate(`../redline/${redlineName}/clustering`);
    } finally {
      setProcessing(false);
    }
  }, [checkedFiles, matter.client.number, matter.number, matter.redlines.length, navigate]);

  const onRedline = useCallback(async () => {
    if (checkedFiles.length === 0) return;
    setProcessing(true);
    try {
      const redlineName = `Redline ${matter.redlines.length + 1}`;
      await createAndSelectRedlineFiles(
        checkedFiles,
        matter.number,
        matter.client.number,
        redlineName,
      );
      navigate(`../redline/${redlineName}/diff`);
    } finally {
      setProcessing(false);
    }
  }, [checkedFiles, matter.client.number, matter.number, matter.redlines.length, navigate]);

  const updateRedlineButtonStatus = useCallback(() => {
    setIsRedlineButtonDisabled(checkedFiles.length <= 1);
  }, [checkedFiles]);

  useEffect(() => {
    updateRedlineButtonStatus();
  }, [checkedFiles, updateRedlineButtonStatus]);

  return (
    <DataRoomToolDropdown
      processing={processing}
      title="Redline"
      testId="redline-button"
      icon={RedlineIcon}
      options={[
        { title: 'Run a Redline', icon: RedlineIcon, onClick: onRedline },
        { title: 'Cluster', icon: ClusterIcon, onClick: onCluster },
      ]}
      isButtonDisabled={isRedlineButtonDisabled}
      resizeAction={true}
    />
  );
};
