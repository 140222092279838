import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { ClientMatterWithDetails } from '@/common/types';
import LoadingSpinner from '@/components/LoadingSpinner';
import { dataRoomProcessingType } from '@/pages/overview/dataroom/content/common/types';

interface ActiveTabProps {
  matter: ClientMatterWithDetails | undefined;
  toolIdentifier: string;
  to: string;
  feature: boolean;
  isPanelCollapsed: boolean;
}

export const ActiveTabLink = (props: PropsWithChildren<ActiveTabProps>) => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const dataRoomInfo = props.matter;

  let toolProcessing: dataRoomProcessingType = {
    startTime: new Date(),
    finishTime: new Date(),
    complete: true,
  };

  const referencesAreProcessing = useMemo(() => {
    return !dataRoomInfo?.finishedProcessingMissingDocs;
  }, [dataRoomInfo?.finishedProcessingMissingDocs]);

  const signaturesAreProcessing = useMemo(() => {
    return (
      dataRoomInfo?.dataRoomFiles.length === 0 ||
      dataRoomInfo?.dataRoomFiles.some((file) => file.missingSignature === null)
    );
  }, [dataRoomInfo?.dataRoomFiles]);

  const financingsAreProcessing = useMemo(() => {
    return !dataRoomInfo?.finishedProcessingVetoData;
  }, [dataRoomInfo?.finishedProcessingVetoData]);

  const clausesAreProcessing = useMemo(() => {
    return !dataRoomInfo?.finishedProcessingMAClausesData;
  }, [dataRoomInfo]);

  switch (props.toolIdentifier) {
    case 'MISSING_DOCUMENT':
      toolProcessing = {
        startTime: new Date(dataRoomInfo?.createdAt ?? 0),
        finishTime: new Date(
          new Date(dataRoomInfo?.createdAt ?? 0).getTime() +
            1000 * 60 * (30 + (dataRoomInfo?.dataRoomFiles?.length ?? 0)),
        ),
        complete: !referencesAreProcessing,
      };
      break;
    case 'MISSING_SIGNATURE':
      toolProcessing = {
        startTime: new Date(dataRoomInfo?.createdAt ?? 0),
        finishTime: new Date(
          new Date(dataRoomInfo?.createdAt ?? 0).getTime() +
            1000 * 60 * 15 * Math.ceil((dataRoomInfo?.dataRoomFiles?.length ?? 0) / 10.0),
        ),
        complete: !signaturesAreProcessing,
      };
      break;
    case 'FINANCE_REVIEW':
      toolProcessing = {
        startTime: new Date(dataRoomInfo?.createdAt ?? 0),
        finishTime: new Date(
          new Date(dataRoomInfo?.createdAt ?? 0).getTime() +
            1000 * 60 * 15 * Math.ceil((dataRoomInfo?.dataRoomFiles.length ?? 0) / 10.0),
        ),
        complete: !financingsAreProcessing,
      };
      break;
    case 'CONTRACT_CLAUSES':
      toolProcessing = {
        startTime: new Date(dataRoomInfo?.createdAt ?? 0),
        finishTime: new Date(
          new Date(dataRoomInfo?.createdAt ?? 0).getTime() +
            1000 * 60 * (30 + (dataRoomInfo?.dataRoomFiles.length ?? 0)),
        ),
        complete: !clausesAreProcessing,
      };
      break;
    default:
      break;
  }

  const toolProgress = useMemo(() => {
    if (toolProcessing.startTime && toolProcessing.finishTime) {
      const startTime = toolProcessing.startTime.getTime();
      const finishTime = toolProcessing.finishTime.getTime();
      const currentTime = date.getTime();

      const progress = ((currentTime - startTime) / (finishTime - startTime)) * 100.0;
      return Math.min(progress, 95);
    }

    return 0;
  }, [toolProcessing.startTime, toolProcessing.finishTime, date]);

  return (
    <NavLink
      to={props.to}
      className={({ isActive }) =>
        `${toolProcessing.complete ? 'hover:bg-[#303030] cursor-pointer' : 'cursor-default'} ${props.feature ? 'flex' : 'hidden'} ${props.isPanelCollapsed && 'justify-center'} relative rounded-[8px] h-[60px] mb-[4px] font-bold text-[14px] items-center px-[13px] py-[20px] text-marveri-white 
         ${isActive ? 'bg-[#303030]' : ''}`
      }
      onClick={(e) => {
        if (!toolProcessing.complete) {
          e.preventDefault();
        }
      }}
    >
      <div
        className={`${!toolProcessing.complete ? 'absolute left-0 top-0 z-0 h-full rounded-l-[8px]' : 'hidden'}`}
        style={{
          width: `${toolProgress}%`,
          backgroundColor: '#303030',
        }}
      ></div>
      <div className={`${!toolProcessing.complete && 'w-full opacity-50'} relative z-10 flex `}>
        {!toolProcessing.complete && props.isPanelCollapsed ? (
          <LoadingSpinner />
        ) : (
          <>{props.children}</>
        )}
        {!toolProcessing.complete && (
          <div className="absolute right-0">
            <LoadingSpinner />
          </div>
        )}
      </div>
    </NavLink>
  );
};
