import { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';

import { ClientMatterWithDetails, DataRoomClientFile, FolderConfigData } from '@/common/types';
import { FilesByPath } from '@/pages/overview/dataroom/content/common/types';

export type IOverviewContext = {
  matter: ClientMatterWithDetails;
  isQueryInProgress: boolean;
  setIsQueryInProgress: (val: boolean) => void;
  userMessage: string;
  setUserMessage: (val: string) => void;
  setIsPanelCollapsed: (val: boolean) => void;
  isPanelCollapsed: boolean;
  selectedReferenceFocus: string;
  setSelectedReferenceFocus: (val: string) => void;
  selectedRefHighlight: number[];
  setSelectedRefHighlight: (highlight: number[]) => void;
};

export const organizeDocumentsByPath = (files: DataRoomClientFile[]): FilesByPath => {
  const filesByPath: FilesByPath = {};

  files.forEach((file) => {
    const folderName = file.path && file.path.length ? file.path : '/';
    if (!filesByPath[folderName]) {
      filesByPath[folderName] = [file];
    } else {
      filesByPath[folderName].push(file);
    }
  });

  return filesByPath;
};

export const formatDate = (inputDate: Date | null) => {
  if (inputDate === null) return null;
  const date = new Date(inputDate);
  /** Date time comes through as UDT then converted to EDT, results in date being behind one day.
   * Converted date must be advanced by 1 day. **/
  date.setDate(date.getDate() + 1);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const formattedDate = `${year}.${month}.${day}`;

  return formattedDate;
};

export const formatFileDisplay = (
  file: Pick<DataRoomClientFile, 'name' | 'date' | 'company' | 'subject' | 'docTitle'>,
) => {
  let renamedFile: string = '';
  const extension = '.' + file.name.split('.').slice(-1)[0];
  const formattedDate = formatDate(file.date);
  const hasDate = formattedDate !== null;
  const hasCompany = file.company !== null;
  const hasSubject = file.subject !== '' && file.subject !== null;
  renamedFile = `${hasDate ? formattedDate + ' ' : ''}${hasCompany ? file.company + ' - ' : ''}${
    file.docTitle
  }${hasSubject ? ' ' + `(${file.subject})` : ''}${
    extension && extension.charAt(0) === '.' ? `${extension}` : ''
  }`;
  return renamedFile;
};

export const checkFileNameFormat = (
  files: Pick<DataRoomClientFile, 'name' | 'displayName' | 'originalPathname'>[],
) => {
  const allOriginal = files.every((file) => file.originalPathname.includes(file.displayName));
  const allMarveri = files.every((file) => !file.originalPathname.includes(file.displayName));

  if (allOriginal) return 'ORIGINAL';
  else if (allMarveri) return 'MARVERI';
  else return 'MIXED';
};

export const useOrganizeDocumentsByPath = (dataRoomFiles: DataRoomClientFile[]) => {
  return useMemo(() => organizeDocumentsByPath(dataRoomFiles), [dataRoomFiles]);
};

export const useFilesAndEmptyFolders = (
  dataRoomFiles: DataRoomClientFile[],
  folderConfig: FolderConfigData,
) => {
  const pathOrganizedDocuments = useOrganizeDocumentsByPath(dataRoomFiles);

  const filesAndEmptyFolders = useMemo(() => {
    const filesByPath = pathOrganizedDocuments;
    const folders = folderConfig?.emptyFolders || [];
    folders.forEach((folder) => {
      if (!Object.keys(filesByPath).includes(folder)) {
        filesByPath[folder] = [];
      }
    });
    return filesByPath;
  }, [pathOrganizedDocuments, folderConfig?.emptyFolders]);

  return filesAndEmptyFolders;
};

export type DropdownItems = {
  icon?: string;
  title: string;
  onClick: () => void;
};

export function useOverview() {
  return useOutletContext<IOverviewContext>();
}
