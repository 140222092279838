import { useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';

import DocumentListIcon from '@/assets/icons/document-set-icon.svg';
import ArrowIcon from '@/assets/icons/double-chevron-icon.svg';
import PlusIcon from '@/assets/icons/plus-icon.svg';
import RedlineIcon from '@/assets/icons/redline-icon.svg';
import { ClientMatterWithDetails, DataRoomClientFile } from '@/common/types';
import LoadingSpinner from '@/components/LoadingSpinner';
import { toolList } from '@/pages/home/common/toolList';
import { UserHelpNav } from '@/pages/home/common/UserHelpNav';
import { ActiveTabLink } from '@/pages/overview/dataroom/content/common/ActiveTabLink';
import { token, trpcReact } from '@/utils/trpc';

export const ClientMatter = () => {
  const [matter, setMatter] = useState<ClientMatterWithDetails | undefined>();

  const { clientNumber, matterNumber } = useParams();
  const [isQueryInProgress, setIsQueryInProgress] = useState(false);
  const [userMessage, setUserMessage] = useState<string>('');
  const [isPanelCollapsed, setIsPanelCollapsed] = useState(false);
  const [selectedReferenceFocus, setSelectedReferenceFocus] = useState('referenced');
  const [selectedRefHighlight, setSelectedRefHighlight] = useState<number[]>([]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 1040) {
        setIsPanelCollapsed(true);
      }
    };

    window.addEventListener('resize', handleWindowResize);

    handleWindowResize();

    return () => {
      window.addEventListener('resize', handleWindowResize);
    };
  }, []);

  const convertParamToInt = () => {
    if (clientNumber === undefined || matterNumber === undefined) {
      throw new Error('clientNumber and matterNumber must be defined.');
    }
    const clientNumberInt = parseInt(clientNumber);
    const matterNumberInt = parseInt(matterNumber);
    return [clientNumberInt, matterNumberInt];
  };

  const [userClientNumber, userMatterNumber] = convertParamToInt();

  trpcReact.dataRoom.get.useQuery(
    {
      number: userMatterNumber,
      clientNumber: userClientNumber,
    },
    {
      onSuccess: setMatter,
    },
  );

  interface StringDatedDataRoomClientFile extends Omit<DataRoomClientFile, 'date'> {
    date: string | null;
  }
  const convertToDate = (dataRoomFile: StringDatedDataRoomClientFile): DataRoomClientFile => {
    return {
      ...dataRoomFile,
      date: dataRoomFile.date ? new Date(dataRoomFile.date) : null,
    };
  };

  trpcReact.dataRoom.onChange.useSubscription(
    {
      // The token must be defined for the subscription to be defined, but it won't be triggered until the token is set
      token: token ?? '',
      number: userMatterNumber,
      clientNumber: userClientNumber,
    },
    {
      enabled: token !== undefined,
      onData({ data }) {
        // Convert the dates to Date objects since onData apparently converts them to strings
        const dataroomFiles = data.dataRoomFiles;
        // @ts-expect-error "type instantiation is excessively deep and possibly infinite"
        const datedDataRoomFiles: DataRoomClientFile[] = dataroomFiles.map(convertToDate);

        const updatedMatter = {
          ...data,
          dataRoomFiles: datedDataRoomFiles,
          createdAt: new Date(data.createdAt),
          updatedAt: new Date(data.updatedAt),
          folderConfig: {
            ...data.folderConfig,
            emptyFolders: data.folderConfig?.emptyFolders || [],
            hiddenFolders: data.folderConfig?.hiddenFolders || [],
            folderAliases:
              data.folderConfig?.folderAliases.map((alias) => {
                return {
                  ...alias,
                  createdAt: new Date(alias.createdAt),
                  updatedAt: new Date(alias.updatedAt),
                };
              }) || [],
          },
        };
        setMatter(updatedMatter);
      },
    },
  );

  const getSelectedTools = useMemo(() => {
    const overviewOptions = [
      {
        name: 'Document List',
        icon: DocumentListIcon,
        path: 'dataroom',
        toolIdentifier: '',
        toolTip: '',
      },
      {
        name: matter?.redlines && matter.redlines.length > 0 ? 'Prior Redlines' : 'Redline',
        icon: RedlineIcon,
        path: 'redline',
        toolIdentifier: '',
        toolTip: '',
      },
    ];
    const userSelectedTools = matter?.tools;
    const filteredTools = userSelectedTools?.filter(
      (tool) =>
        tool.toolType !== 'RENAME' && tool.toolType !== 'ORGANIZE' && tool.toolType !== 'REDLINE',
    );
    const matchedTools = toolList.filter((tool) =>
      filteredTools?.some((filteredTool) => filteredTool.toolType === tool.toolIdentifier),
    );
    const newOverviewOptions = [...overviewOptions, ...matchedTools];

    return newOverviewOptions;
  }, [matter?.redlines, matter?.tools]);

  return (
    <div className="flex size-full bg-container-dark py-[12px] pr-[12px]">
      <div
        className={`${
          isPanelCollapsed ? 'w-[70px]' : 'w-[264px]'
        } shrink-0 bg-container-dark px-[12px] transition-width duration-300 ease-in-out`}
      >
        <div className={`${isPanelCollapsed && ' items-center'} flex h-full flex-col`}>
          <Link
            to={'/'}
            className="translate-y-[-4px] cursor-pointer px-[4px] pt-[4px] font-marveri-logo text-[20px] font-bold uppercase tracking-widest  text-marveri-white opacity-60 hover:opacity-100"
          >
            {isPanelCollapsed ? 'MV' : 'Marveri'}
          </Link>
          <div className="flex w-full">
            <div
              className={`${
                isPanelCollapsed ? 'm-auto' : 'ml-auto'
              } mb-[28px] mt-[23px] flex h-[28px] w-[36px] cursor-pointer items-center justify-center rounded-[8px] hover:bg-[#292929] `}
              onClick={() => setIsPanelCollapsed((currentState) => !currentState)}
            >
              <img
                src={ArrowIcon}
                className={`${isPanelCollapsed ? 'rotate-180' : ''} transition-transform`}
              />
            </div>
          </div>
          <div
            className={`${isPanelCollapsed ? 'w-[40px]' : 'w-[88px]'} mb-[14px] flex h-[40px]  items-center gap-[4px] rounded-[8px] bg-marveri-white px-[12px] py-[10px] font-medium`}
          >
            <img src={PlusIcon} />
            {!isPanelCollapsed && <span>Tools</span>}
          </div>
          <div
            className={`${isPanelCollapsed && 'items-center'} flex h-[88%] flex-col justify-between`}
          >
            <div>
              {getSelectedTools.map((tool, index) => {
                return (
                  <ActiveTabLink
                    matter={matter}
                    toolIdentifier={tool.toolIdentifier}
                    to={tool.path}
                    feature={true}
                    isPanelCollapsed={isPanelCollapsed}
                    key={index}
                  >
                    <div className="flex gap-[13px]">
                      <img src={tool.icon} className="shrink-0" />
                      {!isPanelCollapsed && <span>{tool.name}</span>}
                    </div>
                  </ActiveTabLink>
                );
              })}
            </div>
            <div className="px-[7px]">
              <UserHelpNav isPanelCollapsed={isPanelCollapsed} />
            </div>
          </div>
        </div>
      </div>
      <div className="flex grow rounded-[12px] bg-marveri-background">
        {matter ? (
          <Outlet
            context={{
              matter,
              isQueryInProgress,
              setIsQueryInProgress,
              userMessage,
              setUserMessage,
              setIsPanelCollapsed,
              isPanelCollapsed,
              selectedReferenceFocus,
              setSelectedReferenceFocus,
              selectedRefHighlight,
              setSelectedRefHighlight,
            }}
          />
        ) : (
          <div className="flex size-full items-center justify-center">
            <LoadingSpinner size={20} />
          </div>
        )}
      </div>
    </div>
  );
};
