import { useState } from 'react';
import { Link } from 'react-router-dom';

import { ClientMatter, DataRoomClientFile } from '@/common/types';
import { UserHelpNav } from '@/pages/home/common/UserHelpNav';
import { DocumentSetCard } from '@/pages/home/content/DocumentSetCard';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';
import { token, trpcReact } from '@/utils/trpc';

export const HomeSidebar = () => {
  const [clientMatters, setClientMatters] = useState<ClientMatter[]>([]);

  trpcReact.clientMatter.getAllForCurrentUser.useQuery(undefined, {
    onSuccess: setClientMatters,
  });

  const addClientMatter = (matterToAdd: ClientMatter) => {
    setClientMatters((current) => {
      if (current.some((matter) => matter.number === matterToAdd.number)) {
        return current;
      }
      return [...current, matterToAdd];
    });
  };

  const removeClientMatter = (matterToRemove: ClientMatter) => {
    setClientMatters((current) => {
      if (!current.some((matter) => matter.client.number === matterToRemove.client.number)) {
        return current;
      }
      return current.filter((matter) => matter.client.number !== matterToRemove.client.number);
    });
  };

  interface StringDatedDataRoomClientFile extends Omit<DataRoomClientFile, 'date'> {
    date: string | null;
  }
  const convertToDate = (dataRoomFile: StringDatedDataRoomClientFile): DataRoomClientFile => {
    return {
      ...dataRoomFile,
      date: dataRoomFile.date ? new Date(dataRoomFile.date) : null,
    };
  };

  trpcReact.clientMatter.onChange.useSubscription(
    {
      token: token ?? '',
    },
    {
      enabled: token !== undefined,
      onData(changeEvent) {
        const { type, data } = changeEvent;
        const dataroomFiles = data.dataRoomFiles;
        // @ts-expect-error "type instantiation is excessively deep and possibly infinite"
        const datedDataRoomFiles: DataRoomClientFile[] = dataroomFiles.map(convertToDate);
        const updatedMatter = {
          ...data,
          createdAt: new Date(data.createdAt),
          updatedAt: new Date(data.updatedAt),
          dataRoomFiles: datedDataRoomFiles,
        };

        if (type === 'create') {
          addClientMatter(updatedMatter);
        } else {
          removeClientMatter(updatedMatter);
        }
      },
      onError() {},
    },
  );

  const clientMattersSorted = clientMatters.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

  return (
    <div
      className={`w-[264px] shrink-0 bg-container-dark px-[12px] transition-width duration-300 ease-in-out`}
      data-testid="home-sidebar"
    >
      <Link
        to={'/'}
        className="cursor-pointer px-[4px] pt-[4px] font-marveri-logo text-[20px] font-bold uppercase tracking-widest  text-marveri-white opacity-60 hover:opacity-100"
      >
        Marveri
      </Link>
      <div className="flex h-[88%] flex-col justify-between px-[4px] pt-[66px]">
        <div className="relative">
          <h2 className="mb-[15px] text-[11px] leading-[20px] text-white opacity-30">
            RECENT PROJECTS
          </h2>
          <ScrollableDiv containerStyle="h-[calc(100vh-295px)]">
            {clientMattersSorted.map((matterItem, index) => {
              return (
                <DocumentSetCard
                  key={`${matterItem.client.number}&${matterItem.number}`}
                  matterItem={{
                    clientName: !matterItem.name ? matterItem.client.name : matterItem.name,
                    number: matterItem.number,
                    clientNumber: matterItem.client.number,
                    numberOfFiles: matterItem._count.dataRoomFiles || 0,
                    index: index,
                  }}
                />
              );
            })}
          </ScrollableDiv>
        </div>
        <UserHelpNav />
      </div>
    </div>
  );
};
